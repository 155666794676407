<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="">
       <div class="row">    
              <div class="col-md-8 col-12">
                 <div class="card-header">
                <h3 class="card-title"> Delivered Orders </h3>
              </div>
              </div>
              <div class="col-md-12 col-12">
                 <div class="srcpadmobie"> 
                 <div class="row">
                   <div class="col-md-3 col-xs-12"> 
             <input class="form-control " type="text" v-model="searchQuery" placeholder="Search" />
          </div>
          <div class="col-md-4 col-5">               
<input type="date" class="form-control topm5" name="date1" id="date1" v-model="date1"/>           
          </div>
            <div class="col-md-4 col-5">              
<input type="date" class="form-control topm5" name="date2" id="date2" v-model="date2"/>          
          </div>
          <div class="col-md-1 col-2">  
            <button v-on:click="getData()" class="btn btn-success btn-sm topm5"><i class="fas fa-search"></i></button>
          </div>
                 </div>
                 </div>
              </div>
       </div>
            </div>
     <div class="hgtblank">&nbsp;</div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <section class="col-lg-12 connectedSortable">
            <div class="">              
              <div class="">
                <div class="tab-content p-0">
                  <div class="chart tab-pane active">
<div class="row">
<div class="col-md-12">
<div class="card card-primary cardpad">              
              <!-- /.card-header -->
<div class="card-body">

<table class="table">
  <tr>
    <th>Order Id</th>
    <th>Name</th>
    <th>Mobile</th>
    <th>Date</th>
    <th>Amount</th>
    <th>Due. Amount</th>
    <th>Action</th>
  </tr>
  <tr v-for="(user, index) in filteredResources" :key="user.id">
    <td><router-link class="linkdark" :to="{ path: '/vieworder/'+ user.id}" v-bind:tooltip="Alignment" append><a>#{{ user.invoice_no }}</a></router-link></td>
    <td>{{ user.name }}</td>
    <td>{{ user.mobile_no }}</td>
    <td>{{ user.pickup_date }}</td>
    <td>Rs. {{ user.grand_amount }}</td>
    <td>Rs. {{ user.balance }}</td>
    <td>
      <div class="row">
        <button v-if="this.action.includes('19')" @click="removeData(user.id, index)" class="btn btn-danger btn-xs"> 
      <i class="fa fa-times-circle"></i> Remove </button>
</div>
    </td>
  </tr>
</table>

              </div>
              <!-- /.card-body -->
            </div>

        </div>
      </div> 
                         <div class="hello">
                      <VueTailwindPagination
      :current="currentPage"
      :total="total"
      :per-page="perPage"
      @page-changed="onPageClick($event)"
    />
                    </div>                     
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default {
  extends:defineComponent,  
   components:{         
         VueTailwindPagination,
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar
      },
    data() {          
      return {
        resourcesaction:[], 
        company_id:this.$store.state.auth.user.data[0].company_id,
        role_id:this.$store.state.auth.user.data[0].role_id,
        token: localStorage.token,
        quote: '',
        user_id:this.$store.state.auth.user.data[0].id, 
        myData: null,
        resources: [],
        showbtn:this.id, 
        searchQuery:'',
        currentPage: '1',
        perPage: '10',
        total: this.total,
        date1:this.date1,  
        date2:this.date2, 
      }
    }, 
    
    computed: {
    filteredResources (){
      if(this.searchQuery){
      return this.resources.filter((user)=>{       
        return user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || user.mobile_no.toLowerCase().includes(this.searchQuery.toLowerCase()) || user.invoice_no.toLowerCase().includes(this.searchQuery.toLowerCase())    
      })
      }else{
        return this.resources;
      }
    }
  },


   methods: {  
     async getAction() {
          var response = await axios.post(
            `http://laundry.ezeehost.com:4909/ClientIspmateApi/getroleeditscreen`, {
              company_id: this.company_id, 
              user_id: this.user_id,
              role_id: this.role_id         
        })      
              this.resourcesaction = response.data;               
              this.action = response.data.data[0].action_ids;   
              this.role_name = response.data.data[0].role_name;  
              console.log(this.action);           
        }, 
     showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
     showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     },  
    removeData(id, index){
      //alert(index);
      if(confirm("Do you really want to delete?")){            
      axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/deleteinvoice', {
              user_id: this.user_id,
              invoice_id: id,
            })
              .then(response => {                  
                  this.resources.splice(index,1);   
                  this.showsuccessmsg('Order delete successfully');                                               
                  this.$router.push({path:'/completeorder'})
                  console.log(response.data.response);  
                  //return response
                }).catch(error => { 
                  this.showfailedmsg('Something went wrong');       
                  console.log(error)        
      });
        } 
    },
    async getData() {
      this.date11 = this.date1 
      this.date22 = this.date2 
      var response = await axios.post(
        `http://laundry.ezeehost.com:4909/ClientIspmateApi/invoice-list`, {
          user_id: this.user_id, 
          company_id: this.company_id,
          page: this.currentPage, 
          order_type: 'delivered', 
          client_id: '',
          from_date: this.date11,
          to_date: this.date22,
          search_data: '',                  
        });      
      this.resources = response.data.data.client_list;
      this.total = response.data.total_record;      
      console.log(this.total)
       
    },
    onPageClick(event) {
      this.currentPage = event;
      this.getData(this.currentPage);
    }, 
    show(id){     
      this.showbtn = id;
    },
    showclose(){          
      this.showbtn = false;
    }       
    },

    mounted() { 
    setTimeout(() => {
      this.myData = 'Example Data';
    }, 2000); 
    this.getData();
    this.getAction();
  },    

  };
</script>

<style>
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 1.25rem 1.25rem 1.25rem !important;
}

  @import '../../assets/dist/css/custom.css'   
</style>